import uuid from "react-uuid"
import vegan_flag from "../../../assets/images/clients/icons/just_lovely_cafe/vegan_flag.png"

export const jesenneMenu = [

  {
    type: "extra",
    item_uuid: uuid(),
    title: "Horúce nealko punče",
    descr: "",
    noDescr: true,
    volume: "",
    price: "",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Jesenný punč",
    descr: "Berries punč sirup, horúca voda, ovocie",
    volume: "250 ml",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúce jabĺčko punč",
    descr: "Spiced apple sirup, horúca voda, ovocie",
    volume: "250 ml",
    price: "3,80 €",
    divideAfter: true
  },

  {
    type: "extra",
    item_uuid: uuid(),
    title: "Horúce limonády",
    descr: "",
    noDescr: true,
    volume: "",
    price: "",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Rakytník",
    descr: "",
    volume: "300 ml / 1 l",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Marhuľa & badián & škorica",
    descr: "",
    volume: "300 ml / 1 l",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hruška & škorica",
    descr: "",
    volume: "300 ml / 1 l",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zázvor",
    descr: "",
    volume: "300 ml / 1 l",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pomaranč",
    descr: "",
    volume: "300 ml / 1 l",
    price: "3,30 € / 6,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    item_uuid: uuid(),
    title: "Káva",
    descr: "",
    noDescr: true,
    volume: "",
    price: "",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Mochaccino",
    descr: "Horúca čokoláda, pomarančový sirup, espresso, mlieko",
    volume: "",
    price: "4,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    item_uuid: uuid(),
    title: "Super “latéčka”",
    descr: "",
    noDescr: true,
    volume: "",
    price: "",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Matcha latte klasik",
    descr: "Zelená matcha prášok, mlieko",
    volume: "300 ml",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Matcha latte modré, s bielou čokoládou",
    descr: "Blue matcha butterfly pea flower prášok, mlieko, sirup, biela čokoláda",
    volume: "300 ml",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kurkumové latte s praženým orieškom",
    descr: "Turmenic prášok, mlieko, sirup pražený oriešok",
    volume: "280 ml",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cviklové latte s ovseným mliekom",
    descr: "Zmes cvikly a orientálnych korenín v jemnom vegánskom prášku, ovsené mlieko",
    volume: "300 ml",
    flagBehindTitle: true,
    flag: vegan_flag,
    flagHeight: "25px",
    price: "4,20 €",
    divideAfter: false
  }
]